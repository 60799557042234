import React, { useState, useEffect, useRef } from 'react';
import { Slider, Typography, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import './App.css';

function App() {
  const [investmentAmount, setInvestmentAmount] = useState(10000);
  const [growthScenario, setGrowthScenario] = useState('base');
  const [chartData, setChartData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const chartRef = useRef(null);

  const calculateInvestmentValue = (initialAmount, scenario, years) => {
    const growthRates = {
      conservative: [0.417, 0.833, 2.500, 5.208, 10.417],
      base: [1.667, 2.917, 8.681, 20.833, 37.879],
      optimistic: [1.667, 6.667, 24.306, 62.500, 98.643]
    };
    const rates = growthRates[scenario];
    
    return Array.from({ length: years }, (_, index) => {
      const year = 2025 + index;
      const value = initialAmount * rates[index];
      return { year, value };
    });
  };

  useEffect(() => {
    const data = calculateInvestmentValue(investmentAmount, growthScenario, 5);
    setChartData(data);
  }, [investmentAmount, growthScenario]);

  const handleScenarioChange = (event, newScenario) => {
    if (newScenario !== null) {
      setGrowthScenario(newScenario);
    }
  };

  const generateYAxisTicks = (data) => {
    const maxValue = Math.max(...data.map(item => item.value));
    const maxTick = Math.ceil(maxValue / 50000) * 50000;
    const interval = Math.ceil(maxTick / 150000) * 50000;
    return [0, interval, interval * 2, interval * 3];
  };

  const formatValue = (value) => {
    const roundedValue = Math.round(value);
    if (roundedValue >= 1000000) return `$${(roundedValue / 1000000).toFixed(1)}M`;
    if (roundedValue >= 1000) return `$${(roundedValue / 1000).toFixed(0)}K`;
    return `$${roundedValue}`;
  };

  const renderCustomizedLabel = (props) => {
    const { x, y, value, index } = props;
    if (activeIndex === index || activeIndex !== -1) return null;
    const formattedValue = formatValue(Math.round(value));
    const padding = 4;
    const backgroundWidth = formattedValue.length * 7 + padding * 2; // Estimate text width
    const backgroundHeight = 14;

    return (
      <g>
        <rect
          x={x - backgroundWidth / 2}
          y={y - backgroundHeight - 5}
          width={backgroundWidth}
          height={backgroundHeight}
          fill="white"
          fillOpacity={0.75}
          rx={4}
          ry={4}
        />
        <text
          x={x}
          y={y - 10}
          fill="#2FAA4A"
          fontSize={12}
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {formattedValue}
        </text>
      </g>
    );
  };

  const scenarioDescriptions = {
    conservative: "Assumptions: 500 stores by 2029, $100k revenue/store, dilution from 2 fundraising rounds, 3x valuation multiple on revenue",
    base: "Assumptions: 1.5k stores by 2029, $100k revenue/store, dilution from 3 fundraising rounds, 5x valuation multiple on revenue",
    optimistic: "Assumptions: 2.5k stores by 2029, $125k revenue/store, dilution from 3 fundraising rounds, 10x valuation multiple on revenue",
  };

  return (
    <div className="App">
      <div className="container">
        <Typography variant="h4" gutterBottom align="center">
          ROI Scenarios
        </Typography>
        <Typography 
          variant="caption" 
          style={{ 
            fontStyle: 'italic', 
            color: '#666', 
            display: 'block',
            fontSize: '12px',
            fontFamily: 'Signika, sans-serif'
          }}
        >
        Note: figures below are hypothetical based on potential upside scenarios. We cannot promise you will see the same results if you invest. Investing in startups is risky - please only make decisions you feel comfortable with!
        </Typography>
        <Typography variant="h6" gutterBottom>
          Investment Amount: ${investmentAmount.toLocaleString()}
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <Slider
            value={investmentAmount}
            onChange={(_, newValue) => setInvestmentAmount(newValue)}
            min={10000}
            max={100000}
            step={5000}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => `$${value.toLocaleString()}`}
            style={{ flex: 1, marginLeft: '20px', marginRight: '20px' }}
          />
        </div>

        <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
          Growth Scenario
        </Typography>
        <ToggleButtonGroup
          value={growthScenario}
          exclusive
          onChange={handleScenarioChange}
          aria-label="growth scenario"
          fullWidth
          size="medium"
          style={{ marginBottom: '4px' }}
        >
          {['conservative', 'base', 'optimistic'].map((scenario) => (
            <ToggleButton 
              key={scenario} 
              value={scenario} 
              aria-label={scenario}
              style={{ padding: '4px 8px' }}
            >
              {scenario.charAt(0).toUpperCase() + scenario.slice(1)}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <Typography 
          variant="caption" 
          style={{ 
            fontStyle: 'italic', 
            color: '#666', 
            display: 'block',
            fontSize: '12px',
            fontFamily: 'Signika, sans-serif'
          }}
        >
          {scenarioDescriptions[growthScenario]}
        </Typography>

        <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
          Projected 5-Year ROI
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart 
            data={chartData}
            margin={{ top: 30, right: 30, left: 0, bottom: 5 }}
            // onMouseMove={(e) => {
            //   if (e.isTooltipActive) {
            //     setActiveIndex(e.activeTooltipIndex);
            //   } else {
            //     setActiveIndex(-1);
            //   }
            // }}
            // onMouseLeave={() => setActiveIndex(-1)}
            ref={chartRef}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" style={{ fontSize: '14px' }} />
            <YAxis 
              tickFormatter={formatValue}
              domain={[0, 'dataMax']}
              ticks={generateYAxisTicks(chartData)}
              style={{ fontSize: '14px' }}
            />
            {/* <Tooltip 
              formatter={(value, name, props) => [`$${Math.round(value).toLocaleString()}`, '']}
              labelFormatter={(label) => `${label}`}
              separator=""
              contentStyle={{ fontSize: '14px' }}
            /> */}
            <Line 
              type="monotone" 
              dataKey="value" 
              stroke="#2FAA4A" 
              strokeWidth={2}
              label={renderCustomizedLabel}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default App;